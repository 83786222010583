import { Injectable } from '@angular/core';
import * as dfn from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const isoDateRegex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2})?$/;

@Injectable({
  providedIn: 'root'
})
export class LocationDateService {

  constructor() { }

  private isOnlyDateNoOffset(value: string): boolean {
    return isoDateRegex.test(value);
  }

  private isDate(value: any): boolean {
    return value instanceof Date;
  }

  private getLocalTimezoneOffset(date: Date): string {
    const timezoneOffset = -date.getTimezoneOffset();
    const offsetHours = Math.floor(timezoneOffset / 60);
    const offsetMinutes = timezoneOffset % 60;
    const sign = offsetHours >= 0 ? '+' : '-';
    return `${sign}${this.zeroPad(Math.abs(offsetHours))}:${this.zeroPad(offsetMinutes)}`;
  }

  private zeroPad(num: number): string {
    return num.toString().padStart(2, '0');
  }

  changeDateTimezone(cDate: Date | string): Date {
    let dateStr: string = "";

    if (this.isDate(cDate)) {
      dateStr = dfn.formatISO((cDate as Date))
    } else {
      dateStr = cDate as string;
    }

    const dateValue = dateStr.trim();
    let inputDate = new Date(dateValue);

    let offset: string = this.isOnlyDateNoOffset(dateValue)
      ? this.getLocalTimezoneOffset(inputDate)
      : dateStr.slice(-6);

    return utcToZonedTime(inputDate, offset);
  }
}
