import pkg from '../../../package.json';

export const environment = {
  appVersion: pkg.version,
  appName: pkg.name,
  production: true, 
  COMPANY_NAME: "Jammy Dude",
  COMPANY_ABB: "jd",
  SHOW_COUNTRY_SELECT: false,
  COUNTRY_SELECT: [ 
    {
      COUNTRY_NAME: "Italia",
      COUNTRY_FLAG: "it",
      COUNTRY_BASE_PATH: "https://api.jammydude.com",
      COUNTRY_MEDIA_PATH: "https://media.jammydude.com",
      COUNTRY_DEFAULT_LANGUAGE: "it-it"
    }
  ],
  DEFAULT_PHONE_PREFIX : "+39",
  MESSAGE_DURATION : 2500,
  ERROR_MESSAGE_DURATION : 0,
  NEW_TRAN_SYMBOL: "",
  EDIT_TRAN_SYMBOL: "", 
  EDIT_TRAN_AFFIX: "booking_",
  ERROR_NOTIFICATION_DURATION : 0,  
  LOADER_DELAY : 200,
  SAFARI_COLOR : "#0d0128",
  HTML_THEME_COLOR : "#ffffff",
  HTML_TITLE_COLOR : "#0d0128",
  REGISTRATION_FULL_ADDRESS: true,
  USE_IUBENDA: true,
  IUBENDA_COOKIE_POLICY: 
  'var _iub = _iub || []; _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"floatingPreferencesButtonDisplay":"bottom-right","perPurposeConsent":true,"siteId":3140985,"whitelabel":false,"cookiePolicyId":41138118,"lang":"it", "banner":{ "acceptButtonDisplay":true,"closeButtonDisplay":false,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"bottom","rejectButtonDisplay":true,"showPurposesToggles":true }};',
  IUBENDA_CONS: 
  'var _iub = _iub || {}; _iub.cons_instructions = _iub.cons_instructions || []; _iub.cons_instructions.push(["init", {api_key: "0wj80VfbyGPCRlu5S1oWlAc8fyGcsvoa"}]);',
  IUBENDA_DEFAULT_HTML: 
  '(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);',
  SHOW_PUBLIC_EVENT: true,
  SHOW_EVENTS_SALE_NAME: true,
  SHOW_CHEK_IN_INFO: true,
  SHOW_ALL_PRIVATE_SALE_CONTACTS: true,
  REGISTER_TYPE: "full"
};
