import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { lastValueFrom } from 'rxjs';
import { LayoutService } from 'src/app/layout/layout.service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PRPVSaleEventFull, PurpleApiResponseStatus } from 'src/core-modules/sdk/api';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { environment } from 'src/environments/default/environment';
import { Guid, Helper, PurpleTranslationPipe } from 'purple-lib';

@Component({
  selector: 'app-private-contacts',
  templateUrl: './private-contacts.component.html',
  styleUrls: ['./private-contacts.component.scss']
})
export class PrivateContactsComponent implements OnInit, OnDestroy {
  validateForm!: UntypedFormGroup;

  constructor(public authSvc: AuthenticationService, private fb: UntypedFormBuilder, private tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService,
    private bookSvc: BookingService, private mesSvc: NzMessageService, private tranPipe: PurpleTranslationPipe, private layoutSvc: LayoutService, private notSvc: NzNotificationService) {
      this.phonePrefixes = Helper.getPhonePrefixes(); 
      this.showAllSaleEventContacts = environment.SHOW_ALL_PRIVATE_SALE_CONTACTS;
    }

  subtitleText: string = this.tranPipe.transform("contact_subtitle", "con noi <span class='dot'>!</span>")
  selectSaleEvents: PRPVSaleEventFull[] = [];
  phonePrefixes: string[] = [];
  showAllSaleEventContacts: boolean = true;
  isKering: boolean = environment.COMPANY_ABB.strEq("ke")

  ngOnDestroy(): void {
    this.layoutSvc.setFullContent(true);
  }

  isBaseSaleEent(se: PRPVSaleEventFull){
    return se.saleEventId.strEq(Guid.empty())
  }

  async ngOnInit(): Promise<void> {
    this.layoutSvc.setFullContent(false);

    this.validateForm = this.fb.group({
      saleEvent: [{value: Guid.empty(), disabled: !this.showAllSaleEventContacts}, [Validators.required]],
      fullName: [{value: (this.authSvc.currentUser?.user?.firstName + " " + this.authSvc.currentUser?.user?.lastName), disabled: false}, [Validators.required]],
      email: [{value: this.authSvc.currentUser?.user?.email, disabled: false}, [Validators.email, Validators.required]],
      phoneNumberPrefix: [{value: this.authSvc.currentUser?.user?.phonePrefix, disabled: false}],
      phoneNumber: [{value: this.authSvc.currentUser?.user?.phone, disabled: false}],
      message: [null, [Validators.required]]
    });

    this.seSvc.currentSaleEvent.next(undefined)
    this.seSvc.getSaleEventUtcByTimeZoneId(undefined);

    this.route.queryParams
      .subscribe(params => {
        this.seSvc.getSaleEventParameters(params);
      });

      if(environment.SHOW_ALL_PRIVATE_SALE_CONTACTS){
        const resp = await lastValueFrom(this.bookSvc.getContactSaleEvents(this.tsvc.currentLanguage.value));

        if (resp.status == PurpleApiResponseStatus.Success && resp.data != undefined) {
          this.selectSaleEvents = resp.data ?? []
        } else {
          this.selectSaleEvents = []
        }
      }else{
        const resp = await lastValueFrom(this.bookSvc.getSaleEventBySaleEventId(this.tsvc.currentLanguage.value, {
          saleEventId: Guid.empty()
        }));

        if (resp.status == PurpleApiResponseStatus.Success && resp.data != undefined) {
          this.selectSaleEvents = [resp.data] ?? []
        } else {
          this.selectSaleEvents = []
        }
      }
    

    var sec: string | undefined = this.route.children[0]?.snapshot.params['saleEventCode'] ?? undefined;

    if (sec != undefined) {
      //console.log("Trovo sec: ", sec);

      const resp = await lastValueFrom(this.bookSvc.getSaleEventBySaleEventCode(this.tsvc.currentLanguage.value, {
        saleEventCode: sec
      }));

      if (resp.status == PurpleApiResponseStatus.Success && resp.data != undefined) {
        this.seSvc.currentSaleEvent.next(resp.data)
        this.seSvc.getSaleEventUtcByTimeZoneId(resp.data.timeZoneName);
      } else {
        this.getBaseInfo()
      }
    } else {
      this.getBaseInfo()
    }

    //console.log("currentSaleEvent: ", this.seSvc.currentSaleEvent);
  }

  async getBaseInfo() {
    //console.log("Non trovo sec o se non valido");

    const resp = await lastValueFrom(this.bookSvc.getSaleEventBySaleEventId(this.tsvc.currentLanguage.value, {
      saleEventId: Guid.empty()
    }));

    //console.log("Resp: ", resp)
    if (resp.status == PurpleApiResponseStatus.Success) {
      this.seSvc.currentSaleEvent.next(resp.data)
      this.seSvc.getSaleEventUtcByTimeZoneId(resp.data?.timeZoneName);
    } else {
      this.seSvc.currentSaleEvent.next(undefined)
      this.seSvc.getSaleEventUtcByTimeZoneId(undefined);
    }
  }

  async submitForm(): Promise<void> {
    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {

      const formResp = await lastValueFrom(this.bookSvc.sendContactFormMessage(this.tsvc.currentLanguage.value, {
        saleEvent: this.validateForm.controls["saleEvent"].value,
        email: this.validateForm.controls["email"].value,
        fullName: this.validateForm.controls["fullName"].value,
        phonePrefix: this.validateForm.controls["phoneNumberPrefix"].value,
        phone: this.validateForm.controls["phoneNumber"].value,
        message: this.validateForm.controls["message"].value,
        clientHost: window.location.hostname
      }));

      if (formResp.status == PurpleApiResponseStatus.Success) {
        this.mesSvc.success(this.tranPipe.transform("message_send_contact_form_ok_response", "Richiesta inviata correttamente"), {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.validateForm.clearValidators();
        this.validateForm.controls["message"].reset();
      } else {
        this.notSvc.error(this.tranPipe.transform('erro_message_default_title','Errore', []), this.tranPipe.transform("message_send_contact_form_ko_response", "Errore! Richiesta non inviata"), 
        {nzDuration: environment.ERROR_NOTIFICATION_DURATION, nzPlacement: "top"});
        
      }
    }
  }


}
