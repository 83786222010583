import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AnimationOptions } from 'ngx-lottie';
import { lastValueFrom } from 'rxjs';
import { LayoutService } from 'src/app/layout/layout.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PurpleApiResponseStatus, UserNextSaleEventBookingItem, UserPastSaleEventBookingItem } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { InstructionsDrawerComponent } from '../drawers/instructions-drawer/instructions-drawer.component';
import { InviteFriendsDrawerComponent } from '../drawers/invite-friends-drawer/invite-friends-drawer.component';
import { QrCodeDrawerComponent } from '../drawers/qr-code-drawer/qr-code-drawer.component';
import { InstructionsModalComponent } from '../modals/instructions-modal/instructions-modal.component';
import { InviteFriendsModalComponent } from '../modals/invite-friends-modal/invite-friends-modal.component';
import { QrCodeModalComponent } from '../modals/qr-code-modal/qr-code-modal.component';
import { PrivateInviteFriendsDrawerComponent } from '../drawers/private-invite-friends-drawer/private-invite-friends-drawer.component';
import { PrivateInviteFriendsModalComponent } from '../modals/private-invite-friends-modal/private-invite-friends-modal.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PurpleLoaderService, PurpleTranslationPipe } from 'purple-lib';
import { SaleEventDatePipe } from 'src/purple-widgets/location-date.pipe.';

@Component({
  selector: 'app-private-my-reservations',
  templateUrl: './private-my-reservations.component.html',
  styleUrls: ['./private-my-reservations.component.scss']
})
export class PrivateMyReservationsComponent implements OnInit, OnDestroy {

  constructor(private tranPipe: PurpleTranslationPipe, public tsvc: AppTranslationService, private loaderSvc: PurpleLoaderService, private layoutSvc: LayoutService
    , private bookSvc: BookingService, private drawerSvc: NzDrawerService, private mesSvc: NzMessageService, private modalSvc: NzModalService,
    private modal: NzModalService, private datepipe: SaleEventDatePipe, private notSvc:NzNotificationService) {
    this.tabTitlePast = this.tranPipe.transform('my_reservations_ast_event_title', 'Eventi passati', []);
    //console.log("TTT", this.tabTitlePast)
    this.tabTitleNext = this.tranPipe.transform('my_reservations_next_event_title', 'Eventi in corso', []);
    this.showCheckInInfo = environment.SHOW_CHEK_IN_INFO;
  }

  subtitleText: string = this.tranPipe.transform("my_reservations_subtitle", "Tutte le prenotazioni e gli eventi a cui hai partecipato <span class='dot'>!</span>")

  pastBookings: UserPastSaleEventBookingItem[] = [];
  nextBookings: UserNextSaleEventBookingItem[] = [];
  options: AnimationOptions = {
    path: '/assets/loaders/' + environment.COMPANY_ABB + '/empty-booking.json',
  };
  showCheckInInfo: boolean = true;
  tabTitlePast: string = "";
  tabTitleNext: string = "";
  basePath: string = localStorage.getItem("BasePath")!;

  ngOnDestroy(): void {
    this.layoutSvc.setFullContent(true);
  }

  async getSaleEventBookings() {
    const resp = await lastValueFrom(this.bookSvc.getUserSaleEventBookings(this.tsvc.currentLanguage.value));

    if (resp.status == PurpleApiResponseStatus.Success) {
      this.pastBookings = resp.data!.pastUserSaleEventBooking;
      this.nextBookings = resp.data!.nextUserSaleEventBooking;
      //console.log("NextBookings: ", this.nextBookings)
    } else {
      this.pastBookings = []
      this.nextBookings = []
    }
  }

  async ngOnInit(): Promise<void> {
    this.loaderSvc.addRequest("general");
    this.layoutSvc.setFullContent(false);

    await this.getSaleEventBookings();
    this.loaderSvc.removeRequest("general");
  }

  addToCalendar(saleEventSlot: UserNextSaleEventBookingItem): string {

    return `${this.basePath}/${this.tsvc.currentLanguage.value}/booking/downloadCalendarBook?SaleEventSlotBookingId=${saleEventSlot.slot.bookingId}`;
  }

  showQrCodeModal() {
    this.modalSvc.create({
      nzContent: QrCodeModalComponent,
      nzTitle: this.tranPipe.transform('qr_code_modal_title', 'QrCode di Accesso', []),
      nzWidth: '1000px',
      nzClosable: true,
      nzFooter: null,
      nzMaskClosable: false
    });
  }

  showQrCodeDrawer() {
    this.layoutSvc.qrCodeOpen.next(true)
    this.drawerSvc.create<QrCodeDrawerComponent, { value: string }, string>({
      nzTitle: undefined,
      nzContent: QrCodeDrawerComponent,
      nzClosable: true,
      nzWrapClassName: "qr-code",
      nzFooter: undefined,
      nzPlacement: 'bottom'
    }).afterClose.subscribe(() => {
      this.layoutSvc.qrCodeOpen.next(false)
    })
  }

  showInviteFriendsDrawer(saleEventSlot: UserNextSaleEventBookingItem) {
    if (saleEventSlot.isPrivateInviteCounter) {
      this.drawerSvc.create<PrivateInviteFriendsDrawerComponent, { value: string }, string>({
        nzTitle: undefined,
        nzContent: PrivateInviteFriendsDrawerComponent,
        nzClosable: false,
        nzWrapClassName: "private-invite-friends",
        nzFooter: undefined,
        nzPlacement: 'bottom',
        nzContentParams: {
          saleEventId: saleEventSlot.saleEventId
        }
      });
    } else {
      this.drawerSvc.create<InviteFriendsDrawerComponent, { value: string }, string>({
        nzTitle: undefined,
        nzContent: InviteFriendsDrawerComponent,
        nzClosable: false,
        nzWrapClassName: "invite-friends",
        nzFooter: undefined,
        nzPlacement: 'bottom',
        nzContentParams: {
          book: saleEventSlot,
        }
      }).afterClose.subscribe(() => {
        this.layoutSvc.qrCodeOpen.next(false)
      })
    }
  }

  pdfSrcNext(saleEventSlot: UserNextSaleEventBookingItem): string {
    return `${this.basePath}/${this.tsvc.currentLanguage.value}/booking/downloadPdfBooks?SaleEventSlotBookingIds=${saleEventSlot.slot.bookingId}`;
  }

  pdfSrcPast(saleEventSlot: UserPastSaleEventBookingItem): string {
    const ses = saleEventSlot.slots.map(m => m.bookingId).join(',')
    return `${this.basePath}/${this.tsvc.currentLanguage.value}/booking/downloadPdfBooks?SaleEventSlotBookingIds=${ses}`;
  }

  showInviteButton(saleEventSlot: UserNextSaleEventBookingItem): boolean{
    if(!saleEventSlot.isPrivateInviteCounter){
      return true;
    }

    if(saleEventSlot.isPrivateInviteCounter && saleEventSlot.userCanInvite){
      return true;
    }

    return false;

  }

  showDeleteButton(saleEventSlot: UserNextSaleEventBookingItem): boolean{
    if(!saleEventSlot.isPrivateInviteCounter){
      return true;
    }

    if(saleEventSlot.isPrivateInviteCounter && saleEventSlot.userCanDelete){
      return true;
    }

    return false;

  }

  showInviteFriendsModal(saleEventSlot: UserNextSaleEventBookingItem) {
    if (saleEventSlot.isPrivateInviteCounter) {
      this.modalSvc.create<PrivateInviteFriendsModalComponent, { saleEventId: string, saleEventName: string, saleEventPhone: string, saleEventEmail: string }>({
        nzContent: PrivateInviteFriendsModalComponent,
        nzTitle: undefined,
        nzWidth: '1000px',
        nzCloseIcon: undefined,
        nzClosable: false,
        nzCentered: true,
        nzClassName: "private-invite-friends-modal",
        nzData: {
          saleEventId: saleEventSlot.saleEventId,
          saleEventEmail: saleEventSlot.saleEventEmail,
          saleEventPhone: saleEventSlot.saleEventPhone,
          saleEventName: saleEventSlot.saleEventName
        },
        nzFooter: null,
        nzMaskClosable: false,
      });
    } else {
      this.modalSvc.create<InviteFriendsModalComponent, UserNextSaleEventBookingItem>({
        nzContent: InviteFriendsModalComponent,
        nzTitle: undefined,
        nzWidth: '1000px',
        nzCloseIcon: undefined,
        nzClosable: false,
        nzCentered: true,
        nzClassName: "invite-friends-modal",
        nzData: saleEventSlot,
        nzFooter: null,
        nzMaskClosable: false,
      });
    }
  }

  showInstructionsModal() {
    this.modalSvc.create({
      nzContent: InstructionsModalComponent,
      nzTitle: this.tranPipe.transform('instructions_modal_title', 'Informazioni Utili', []),
      nzWidth: '1000px',
      nzClosable: true,
      nzFooter: null,
      nzMaskClosable: false
    });
  }

  showInstructionsDrawer() {
    this.layoutSvc.qrCodeOpen.next(true)
    this.drawerSvc.create<InstructionsDrawerComponent, { value: string }, string>({
      nzTitle: this.tranPipe.transform('instructions_modal_title', 'Informazioni Utili', []),
      nzContent: InstructionsDrawerComponent,
      nzClosable: true,
      nzWrapClassName: "qr-code",
      nzFooter: undefined,
      nzPlacement: 'bottom'
    }).afterClose.subscribe(() => {
      this.layoutSvc.qrCodeOpen.next(false)
    })
  }

  unbook(saleEventSlot: UserNextSaleEventBookingItem, isMobile: boolean) {
    this.modal.create(
      {
        nzTitle: isMobile ? this.tranPipe.transform("modal_unbook_slot_title_mobile", "Cancella prenotazione") : this.tranPipe.transform("modal_unbook_slot_title", "Vuoi davvero cancellare la prenotazione??"),
        nzContent: this.tranPipe.transform("modal_unbook_slot_subtitle", "Sei sicuro di voler cancellare la prenotazione \"<b>{2}</b>\" <b>{0} - {1}</b>",
          [this.datepipe.transform(saleEventSlot.slot.slotStartDate, 'shortTime', saleEventSlot.slot.slotGmtInfo.utcOffsetString!) ?? "", this.datepipe.transform(saleEventSlot.slot.slotEndDate, 'shortTime', saleEventSlot.slot.slotGmtInfo.utcOffsetString!) ?? "", saleEventSlot.saleEventName]),
        nzWidth: isMobile ? '80%' : '600px',
        nzClassName: 'ps-modal',
        nzCentered: isMobile ? true : false,
        nzClosable: false,
        nzMaskClosable: false,
        nzOkText: this.tranPipe.transform("modal_unbook_slot_delete_button", "Elimina"),
        nzOnOk: async () => {
          //console.log("Elimino")
          var res = await lastValueFrom(this.bookSvc.unBookAllNextSlot(this.tsvc.currentLanguage.value, {
            saleEventId: saleEventSlot.saleEventId,
            host: window.location.hostname
          }));
          if (res.status == PurpleApiResponseStatus.Success) {
            this.mesSvc.success(this.tranPipe.transform("message_unbook_ok_response", "Prenotazione cancellata com successo"), {
              nzDuration: environment.MESSAGE_DURATION
            });

            await this.getSaleEventBookings();
          } else {
            this.notSvc.error(this.tranPipe.transform('erro_message_default_title','Errore', []), res.message, {nzDuration: environment.ERROR_NOTIFICATION_DURATION, nzPlacement: "top"});
            
          }
        }
      }
    )
  }

}
