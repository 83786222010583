import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { environment } from 'src/environments/default/environment';
import { ReferralService } from 'src/app/referral/referral.service';
import { PRPVSaleEventFull } from 'src/core-modules/sdk/api';
import { Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { PolicyAndUserAttributesService } from 'src/app/policy-and-user-attributes-service';
import { Guid, PurpleEditService, PurpleLoaderService, PurpleTranslationPipe } from 'purple-lib';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  validateForm!: UntypedFormGroup;
  titleText: string = "";
  titleMobText: string = "";
  subSubtitleText: string = "";
  subtitleText: string = this.tranPipe.transform("login_subtitle", "Registrati o accedi<span class='dot'>.</span>")
  seId: string = "";
  subs: Subscription = new Subscription();
  isMobile: boolean = false;
  isKering: boolean = environment.COMPANY_ABB.strEq("ke");
  companyName = environment.COMPANY_NAME ?? "purple_sales_backoffice";

  constructor(private fb: UntypedFormBuilder, private authenticationService: AuthenticationService, private router: Router, private refSvc: ReferralService, private breakpointObserver: BreakpointObserver,
    public tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService, private tranPipe: PurpleTranslationPipe, private editSvc: PurpleEditService, 
    private policySvc: PolicyAndUserAttributesService, private loaderSvc: PurpleLoaderService) {
    this.titleText = this.tranPipe.transform("login_title_new", "Benvenuto in {0}!", [this.companyName]);
    this.titleMobText = this.tranPipe.transform("login_title_mob_new", "Benvenuto in {0}!", [this.companyName]);
    this.subSubtitleText = this.tranPipe.transform("login_log_with_saywow_account", "Accedi con l\'account saywow", [this.companyName]);
  }


  async submitForm(): Promise<void> {
    this.loaderSvc.addRequest("general");
    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {
      const mode = this.validateForm.controls["remember"].value ? 'local' : 'session';

      //console.log("Mode: ", mode)
      await this.authenticationService.login((this.validateForm.controls["email"].value as string).trim(), (this.validateForm.controls["password"].value as string).trim(), mode).then(
        async (authenticated: boolean) => {
          if (authenticated) {
            //console.log('Autenticato');
            await this.refSvc.reedemReferralCode();
            await this.policySvc.checkUserAttributesAndPolicies(this.isMobile);

            if (this.seSvc.returnUrl != undefined) {
              await this.router.navigateByUrl(this.seSvc.returnUrl);
            } else {
              await this.router.navigate([this.tsvc.currentLanguage.value, 'event']);
            }
          }
        }
      )
    }
    
    this.loaderSvc.removeRequest("general");
  }

  
  
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    //console.log("current sale event:", this.seSvc.currentSaleEvent.value)

    this.subs.add(this.breakpointObserver
    .observe(['(max-width: 650px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }));

    this.subs.add(this.seSvc.currentSaleEvent.subscribe((saleEvent: PRPVSaleEventFull | undefined)=>{
      this.seId = saleEvent?.saleEventId ?? Guid.empty();
    }));

    this.validateForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
      remember: [true]
    });

    
    /* if (this.authenticationService.isLoggedIn && !this.authenticationService.isAdminUser()) {
      await this.router.navigate([this.tsvc.currentLanguage.value,'event']);
    } */


    if (this.authenticationService.isLoggedIn && !(this.editSvc.editModeOn.value && this.authenticationService.isAdminUser())) {
      if (this.seSvc.returnUrl != undefined) {
        await this.router.navigateByUrl(this.seSvc.returnUrl);
      } else {
        await this.router.navigate([this.tsvc.currentLanguage.value, 'event']);
      }

    }

    this.route.queryParams
      .subscribe(params => {
        this.seSvc.getSaleEventParameters(params)
      });
  }
}
