import { Pipe, PipeTransform } from '@angular/core';
import * as dfn from 'date-fns';
import { it, enGB, enUS, fr, frCH, itCH } from 'date-fns/locale';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { LocationDateService } from './location-date.service';

const formatMapping: { [key: string]: string } = {
  short: 'Pp',
  medium: 'PPpp',
  long: 'PPPPpppp',
  full: 'PPPPPPPPpppp',
  shortDate: 'P',
  mediumDate: 'PP',
  longDate: 'PPPP',
  fullDate: 'PPPPPPPP',
  shortTime: 'p',
  mediumTime: 'pp',
  longTime: 'ppp',
  fullTime: 'pppp',
  EEE: 'iii', // Abbreviated weekday name
  EEEE: 'iiii', // Full weekday name
  EEEEE: 'iiiii', // Narrow weekday name
};

const localeMapping: { [key: string]: Locale } = {
  'it-it': it,
  'en-gb': enGB,
  'en-us': enUS,
  'fr-fr': fr,
  'ch-fr': frCH,
  'ch-it': itCH,
};

@Pipe({
  name: 'saleEventDate',
})
export class SaleEventDatePipe implements PipeTransform {
  constructor(private tsvc: AppTranslationService, private locDateSvc: LocationDateService) {}

  mapAngularFormatToFns(format: string): string {
    return formatMapping[format] || format;
  }
  mapInputLocaleToLocale(input: string): Locale {
    return localeMapping[input.toLowerCase()] || it;
  }
  
  isValidDate(value: any): boolean {
    return value instanceof Date;
  }

  transform(value: Date | string | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
    if (!value) {
      return null;
    }

    const zonedDate = this.locDateSvc.changeDateTimezone(value); 
    const output = dfn.format(
      zonedDate,
      this.mapAngularFormatToFns(format ?? 'Pp'),
      {
        locale: this.mapInputLocaleToLocale(
          locale ?? this.tsvc.currentLanguage.value
        ),
      }
    );

    return output;
  }
}
