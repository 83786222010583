import { en_GB, en_US, fr_FR, it_IT, NZ_I18N } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { Configuration as API_BASE_PATH_CONFIGURATION } from 'src/core-modules/sdk/api';
import { BASE_PATH as API_BASE_PATH } from 'src/core-modules/sdk/api';

import { Configuration as API_MEDIA_PATH_CONFIGURATION } from 'src/core-modules/sdk/media';
import { BASE_PATH as API_MEDIA_PATH } from 'src/core-modules/sdk/media';

import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { AuthenticationModule } from './authentication/authentication.module';
import { LayoutModule } from './layout/layout.module';
import { ReferralModule } from './referral/referral.module';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import fr from '@angular/common/locales/fr';
import it from '@angular/common/locales/it';
import * as enGb from '@angular/common/locales/en-GB';
import * as enUs from '@angular/common/locales/en';

import { EditUserAttributesModalComponent } from './private/modals/edit-user-attributes-modal/edit-user-attributes-modal.component';
import { CheckUserAttributesDrawerComponent } from './private/drawers/check-user-attributes-drawer/check-user-attributes-drawer.component';
import {
  MailOutline, LockOutline, UserOutline, PlayCircleOutline, ManOutline, HomeOutline, CalendarOutline, FieldTimeOutline, ShoppingOutline, PhoneOutline, PauseCircleOutline,
  InstagramOutline, FacebookOutline, QrcodeOutline, GlobalOutline, LoadingOutline, CloseOutline, WarningOutline, PrinterOutline, ShareAltOutline, ArrowRightOutline, ArrowLeftOutline,
  PlusOutline, MinusOutline, DeleteOutline, WhatsAppOutline, CloseSquareOutline, TagOutline, RightOutline, SyncOutline, TeamOutline, InfoCircleOutline, EnvironmentOutline, BarcodeOutline,
  StarTwoTone, ArrowDownOutline, SaveOutline, TagTwoTone, ArrowUpOutline
} from '@ant-design/icons-angular/icons';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { APP_TRANSLATION_SERVICE, APP_MEDIA_SERVICE, PURPLE_LIB_MEDIA_PATH, PURPLE_LIB_USER_TOKEN, PurpleEditTextModule, PurpleGeneralDirectiveModule, PurpleErrorModule, PurpleLoaderModule, PurpleEditImageModule, PurpleTranslationPipe, PurplePipeModule } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { AppMediaService } from 'src/core-modules/medias/media.service';
import { StorageService } from 'src/core-modules/storage/storage.service';
import { LocalizationModule } from 'src/core-modules/localization/localization.module';
import { ErrorModule } from './error/error.module';
import { IAuthenticatedUser } from 'src/core-modules/authentication/IAuthenticatedUser';
import { AuthenticationInterceptor } from 'src/core-modules/authentication/authentication.interceptor';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PurpleProjectWidgetsModule } from 'src/purple-widgets/purple-project-widgets.module';
import { PrivateModule } from './private/private.module';
import { PublicModule } from './public/public.module';

registerLocaleData(it, "it-it");
registerLocaleData(it, "it");
registerLocaleData(fr, "fr-fr");
registerLocaleData(fr, "fr");
registerLocaleData(enUs.default, "en-us");
registerLocaleData(enGb.default, "en-gb");

const icons = [MailOutline, LockOutline, UserOutline, PlayCircleOutline, ManOutline, HomeOutline, CalendarOutline, FieldTimeOutline, ShoppingOutline, PhoneOutline,
  PauseCircleOutline, InstagramOutline, FacebookOutline, QrcodeOutline, GlobalOutline, LoadingOutline, CloseOutline, WarningOutline, PrinterOutline, ShareAltOutline,
  ArrowRightOutline, ArrowLeftOutline, PlusOutline, MinusOutline, DeleteOutline, WhatsAppOutline, CloseSquareOutline, TagOutline, RightOutline, SyncOutline, TeamOutline,
  InfoCircleOutline, EnvironmentOutline, BarcodeOutline, StarTwoTone, ArrowDownOutline, ArrowUpOutline, SaveOutline, TagTwoTone];


@NgModule({
  declarations: [
    AppComponent,
    EditUserAttributesModalComponent,
    CheckUserAttributesDrawerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PurpleEditTextModule,
    PurpleGeneralDirectiveModule,
    PurpleEditTextModule,
    PurpleEditImageModule,
    BrowserAnimationsModule,
    
    
    ReactiveFormsModule,
    FormsModule,
    NzMenuModule,
    NzLayoutModule,
    NzSelectModule,
    NzMenuModule,
    NzSelectModule,
    NzIconModule,
    NzDrawerModule,
    NzButtonModule,
    NzNotificationModule,
    PurplePipeModule,
    PurpleProjectWidgetsModule,    

    PurpleLoaderModule.forRoot(),
    PurpleErrorModule.forRoot(),
    LocalizationModule,

    
    ErrorModule,
    AuthenticationModule,
    PublicModule,
    PrivateModule,
    LayoutModule,
    ReferralModule,
    JwtModule.forRoot({})
  ],
  providers: [


    {
      provide: API_BASE_PATH_CONFIGURATION,
      useFactory: (storageSvc: StorageService) => new API_BASE_PATH_CONFIGURATION(
        {
          basePath: localStorage.getItem("BasePath") as string,
          accessToken: ()=> storageSvc.get<IAuthenticatedUser>("CurrentUser")?.token??undefined
        }
      ),
      deps: [StorageService],
      multi: false
    },
    {
      provide: API_MEDIA_PATH_CONFIGURATION,
      useFactory: (storageSvc: StorageService) => new API_MEDIA_PATH_CONFIGURATION(
        {
          basePath: localStorage.getItem("MediaPath") as string,
          accessToken: ()=> storageSvc.get<IAuthenticatedUser>("CurrentUser")?.token??undefined
        }
      ),
      deps: [StorageService],
      multi: false
    },
    {
      provide:JWT_OPTIONS,
      useFactory: (storageSvc: StorageService) => storageSvc.get<IAuthenticatedUser>("CurrentUser")?.token ?? "",
      deps: [StorageService]
    },
    { provide: NZ_I18N, useValue: it_IT },
    { provide: NZ_ICONS, useValue: icons },
    {
      provide: LOCALE_ID,
      deps: [StorageService],
      useFactory: (storageSvc: StorageService) => storageSvc.get<string>("Language") ?? localStorage.getItem("DefaultLanguage")
      //useValue: 'en-us'
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => {
        switch (localId) {
          case 'en-us':
            return en_US;
          case 'en-gb':
            return en_GB;
          case 'it-it':
            return it_IT;
          case 'it':
            return it_IT;
          case 'fr-fr':
            return fr_FR;
          case 'fr':
            return fr_FR;
          default:
            return it_IT;
        }
      },
      deps: [LOCALE_ID]
    },
    { 
      provide: APP_TRANSLATION_SERVICE, 
      deps: [AppTranslationService],
      useExisting: AppTranslationService
    },
    { 
      provide: APP_MEDIA_SERVICE, 
      deps: [AppMediaService],
      useExisting: AppMediaService
    },
    { provide: API_BASE_PATH, useValue: (localStorage.getItem("BasePath") as string) },
    { provide: PURPLE_LIB_MEDIA_PATH, 
      useFactory: () => (localStorage.getItem("MediaPath") as string)
    },
    { provide: API_MEDIA_PATH, useValue: (localStorage.getItem("MediaPath") as string)},
    {
      provide: PURPLE_LIB_USER_TOKEN,
      useFactory: (storageSvc: StorageService) =>  storageSvc.get<IAuthenticatedUser>("CurrentUser")?.token??undefined,
      deps: [StorageService],
      multi: false
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) { }

}
