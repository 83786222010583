import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsComponent } from './contacts/contacts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SaleEventsPreviewComponent } from './sale-events-preview/sale-events-preview.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { RouterModule } from '@angular/router';
import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import player from 'lottie-web';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { PurpleEditImageModule, PurpleEditTextModule, PurpleGeneralDirectiveModule, PurplePipeModule } from 'purple-lib';
import { PurpleProjectWidgetsModule } from 'src/purple-widgets/purple-project-widgets.module';
import { SaleEventDatePipe } from 'src/purple-widgets/location-date.pipe.';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    ReactiveFormsModule,
    NzLayoutModule,
    PurplePipeModule,
    NzDropDownModule,
    PurpleGeneralDirectiveModule,
    PurpleEditTextModule,
    PurpleEditImageModule,
    NzButtonModule,
    NzTabsModule,
    RouterModule,
    NzNotificationModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    PurplePipeModule,
    PurpleProjectWidgetsModule
  ],
  declarations: [ContactsComponent, SaleEventsPreviewComponent, TermsAndConditionsComponent],
  providers: [SaleEventDatePipe]
})
export class PublicModule { }
