import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PurpleTranslationPipe } from 'purple-lib';
import { lastValueFrom } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PurpleApiResponseStatus } from 'src/core-modules/sdk/api';
import { StorageService } from 'src/core-modules/storage/storage.service';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-redeem-referral',
  templateUrl: './redeem-referral.component.html',
  styleUrls: ['./redeem-referral.component.scss']
})
export class RedeemReferralComponent implements OnInit {

  constructor(private tsvc: AppTranslationService, private bookSvc: BookingService, private route: ActivatedRoute, private tranPipe: PurpleTranslationPipe,
    private mesSvc: NzMessageService, private storageSvc: StorageService, private router: Router, private notSvc: NzNotificationService) { }
  //referralId: string | undefined;

  isKering: boolean = environment.COMPANY_ABB.strEq("ke");

  async ngOnInit() {
    this.route.queryParams
      .subscribe(async params => {
        const referralId = params.id;

        const res = await lastValueFrom(this.bookSvc.checkReferralCode(this.tsvc.currentLanguage.value, {
          referralId: referralId
        }));

        if (res.status == PurpleApiResponseStatus.Success) {
          this.storageSvc.set('Referral', res.data, 'session');
          await this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'detail', res.data?.saleEventCode], {queryParams: {returnUrl: "/"+this.tsvc.currentLanguage.value+"/event/detail/"+res.data?.saleEventCode}});

        } else if (res.status == PurpleApiResponseStatus.Warning) {

          if(res.data != undefined){
            this.storageSvc.set('Referral', res.data, 'session');

            this.mesSvc.warning(res.message, {
              nzDuration: 5000
            });

            await this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'detail', res.data?.saleEventCode], {queryParams: {returnUrl: "/"+this.tsvc.currentLanguage.value+"/event/detail/"+res.data?.saleEventCode}});
          }else{
            this.mesSvc.warning(res.message, {
              nzDuration: 5000
            });
  
            await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
          }

        } else {
          this.notSvc.error(this.tranPipe.transform('erro_message_default_title','Errore', []), res.message, {nzDuration: environment.ERROR_NOTIFICATION_DURATION, nzPlacement: "top"});
          
          if(this.isKering){
            await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);

          }else{
            await this.router.navigate([this.tsvc.currentLanguage.value, ]);
          }
        }
      });

      await this.router.navigate(['404']);

  }

}
