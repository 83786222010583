<div
  class="sale-events-preview-main-container ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center ps-align-items-start">
  <div class="ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center">

    <!-- <app-banner-slide *ngIf="carouselSlides.length > 0" class="ps-w-100" carouselId="homepage_banner" [carouselSlides]="carouselSlides"></app-banner-slide> -->



    <div
      class="sale-events-preview-sales-external-container ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-start">
      <div class="sale-events-preview-title-container">
        <h1 class="ps-w-100 ps-m-0 ps-d-none ps-d-md-block purple-title" purpleEditableText tranKey='sale_events_preview_title'>{{ 'sale_events_preview_title' | translation: 'Ciao': []}}</h1>
        <h2 class="ps-w-100 ps-m-0 ps-d-none ps-d-md-block purple-subtitle" purpleEditableText tranKey='sale_events_preview_subtitle' [innerHtml]="subtitleText! | safeHtml"></h2>
        <h2 class="ps-w-100 ps-m-0 ps-d-flex ps-d-md-none purple-title" purpleEditableText tranKey='sale_events_preview_title_mobile'>{{ 'sale_events_preview_title_mobile' | translation: 'Le prossime private sales': []}}</h2>
      </div>
      <ng-container *ngIf="saleEvents.length > 0; else noSales">
        <div 
        class="sale-events-preview-sales-container ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-start">
        <div *ngFor="let sale of saleEvents"
          class="sale-event-preview-sale-box-container sw-border-radius ps-col-xl-4 ps-col-md-6 ps-col-12 ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-center">
          <div
            class="sale-event-preview-sale-box sw-border-radius ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-start ps-justify-content-center">
            <h1 class="ps-w-100 sale-event-city ps-text-center">
              {{sale.saleEvent.addressCity??"Non specificata"}}
            </h1>
            <img contentId="{{sale.saleEvent.saleEventId}}-preview" contentTypeId="sale-event-preview-image" purpleEditableImage
                        class="sale-event-image sw-border-radius"/>
            <h3 *ngIf="showName" class="sale-event-name ps-w-100 ps-text-center">
              {{sale.saleEvent.saleEventName}}
            </h3>
            <p class="sale-event-dates ps-w-100 ps-text-center">
              {{ sale.userSlotStartDate| saleEventDate:"dd MMMM" : sale.saleEventGmtInfo.utcOffsetString}} - {{ sale.userSlotEndDate | saleEventDate:"dd MMMM": sale.saleEventGmtInfo.utcOffsetString}}
            </p>
            <button nz-button purpleEditableText [routerLink]="['/',tsvc.currentLanguage.value, 'event', 'detail', sale.saleEvent.saleEventCode]" nzDanger [nzType]="sale.isBooked?'primary':'default'"
            class="sale-event-book-button ps-w-100 sw-border-radius"
            nzType="primary">
            <container-element [ngSwitch]="sale.status">
              <span purpleEditableText tranKey='sale_events_preview_book_now' *ngSwitchCase="'canBook'">{{ 'sale_events_preview_book_now' | translation: 'Prenota ora': []}}</span>
              <span purpleEditableText tranKey='sale_events_preview_booked' *ngSwitchCase="'booked'">{{ 'sale_events_preview_booked' | translation: 'Prenotato': []}}</span>
              <span purpleEditableText tranKey='sale_events_preview_coming_soon' *ngSwitchCase="'preview'">{{ 'sale_events_preview_coming_soon' | translation: 'Vedi': []}}</span>
              <span purpleEditableText tranKey='sale_events_preview_error' *ngSwitchDefault>{{ 'sale_events_preview_error' | translation: 'Errore': []}}</span>
            </container-element>
          </button>

          <button *ngIf="checkCanInvite(sale)" nz-button purpleEditableText  purpleEditableText tranKey='sale_events_preview_invite_button'
           class="sale-event-invite-button ps-w-100 sw-border-radius" (click)="InviteFriend(sale)"
            nzType="primary">{{ 'sale_events_preview_invite_button' | translation: 'Invita Amici': []}}</button>
          </div>
        </div>
      </div>
      </ng-container>

    </div>
  </div>
</div>


<ng-template #noSales>
  <div class="ps-w-100 ps-d-flex ps-justify-content-center no-booking-box-container">

    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center no-booking-box">

        <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-mb-2">
          <h1 purpleEditableText tranKey='sale_events_preview_no_next_book_title' class="error-title ps-w-100">{{ 'sale_events_preview_no_next_book_title' | translation: 'Nessuna private sale in corso!': []}}</h1>
          <h3 purpleEditableText tranKey='sale_events_preview_no_next_book_subtile' class="error-subtitle ps-w-100">{{ 'sale_events_preview_no_next_book_subtile' | translation: 'Tante private sale ti aspettano':
            []}}</h3>
          <div purpleEditableText tranKey='sale_events_preview_no_next_book_subtile_link' class="error-back-home ps-w-100">{{ 'sale_events_preview_no_next_book_subtile_link' | translation: 'Rimani aggiornato sui nostri social per scoprire le prossime private sale':
            []}}
          </div>
        </div>
        <div class="ps-w-100 ps-d-none ps-d-md-flex ps-justify-content-center animation-container">

          <ng-lottie [options]="options" width="300px" height="auto"></ng-lottie>
        </div>
        <div class="ps-w-100 ps-d-flex ps-d-md-none ps-justify-content-center animation-container">

          <ng-lottie [options]="options" width="210px" height="auto"></ng-lottie>
        </div>
      </div>
    </div>
</ng-template>
